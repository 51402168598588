import { Appear, Head, Image, Notes } from "mdx-deck";
import { swiss, prism, dracula } from "mdx-deck/themes";
import { custom } from "../../../../../../theme";
import logo from "../../../../../../images/logo.png";
import what from "../../../../../../images/what.gif";
import why from "../../../../../../images/why.gif";
import documentation from "../../../../../../images/documentation.gif";
import consistency from "../../../../../../images/consistency.gif";
import confidence from "../../../../../../images/confidence.gif";
import productivity from "../../../../../../images/productivity.gif";
import jest from "../../../../../../images/jest.png";
import React from 'react';
export default {
  Appear: Appear,
  Head: Head,
  Image: Image,
  Notes: Notes,
  swiss: swiss,
  prism: prism,
  dracula: dracula,
  custom: custom,
  logo: logo,
  what: what,
  why: why,
  documentation: documentation,
  consistency: consistency,
  confidence: confidence,
  productivity: productivity,
  jest: jest,
  React: React
};