import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { jsx as ___EmotionJSX } from "@emotion/core";
export var getLanguage = function getLanguage(className) {
  var match = /language-(\w*)/.exec(className || 'language-javascript');
  var lang = 'javascript';

  if (match && match.length > 1) {
    lang = match[1];
  }

  return lang;
};
export var pre = function pre(props) {
  return props.children;
};
export var code = function code(props) {
  var language = getLanguage(props.className);
  return ___EmotionJSX(SyntaxHighlighter, _extends({
    language: language
  }, props));
};
export default {
  components: {
    pre: pre,
    code: code
  }
};