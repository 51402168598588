import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import { Prism } from 'react-syntax-highlighter';
import merge from 'lodash.merge';
import { getLanguage } from './syntax-highlighter';
import { jsx as ___EmotionJSX } from "@emotion/core";
export var pre = function pre(props) {
  return props.children;
};
export var createCode = function createCode(opts) {
  if (opts === void 0) {
    opts = {};
  }

  return function (props) {
    var language = getLanguage(props.className);
    return ___EmotionJSX(Prism, _extends({}, opts, {
      language: language
    }, props));
  };
};
export default (function (theme) {
  return merge(theme, {
    components: {
      pre: pre,
      code: createCode(theme.prism)
    }
  });
});